document.addEventListener('DOMContentLoaded', function() {
  const mellodiPlayerElement = document.getElementById('mellodi-player');
  const mellodiPlayerAudioElement = mellodiPlayerElement.querySelector('audio');
  const mellodiPlayerAlbumCoverElement = mellodiPlayerElement.querySelector('.album-cover');
  const mellodiPlayerTrackTitleElement = mellodiPlayerElement.querySelector('.title');
  const mellodiPlayerArtistNameElement = mellodiPlayerElement.querySelector('.artist');
  const mellodiPlayerPlayPauseControl = mellodiPlayerElement.querySelector('.track-control.play-pause');
  const mellodiPlayerCurrentTimeElement = mellodiPlayerElement.querySelector('.current-time');
  const mellodiPlayerDurationElement = mellodiPlayerElement.querySelector('.duration');
  const mellodiPlayerProgressBarElement = mellodiPlayerElement.querySelector('#playback-slider');
  const mellodiPlayerVolumeMuteElement = mellodiPlayerElement.querySelector('.mute');
  const mellodiPlayerVolumeSliderElement = mellodiPlayerElement.querySelector('#volume-slider');

  var mellodiPlayerCurrentTrackData = {};
  var nextTracksUuidsString = sessionStorage.getItem('nextTracksUuids');
  var mellodiPlayerLogPlayTimeoutId = null;

  function getFirstUuidFromQueue() {
    return nextTracksUuidsString.split(',')[0]
  }

  function removeFirstUuidFromQueue() {
    var uuids = nextTracksUuidsString.split(',');
    uuids.shift();
    sessionStorage.setItem('nextTracksUuids', uuids.join(','));
  }

  if (nextTracksUuidsString) {
    let currentTrackUuid = getFirstUuidFromQueue();
    getTrackData(currentTrackUuid)
    .then(function(response) {
      setCurrentTrackData(response);
    }, function(error) {
      console.log(error);
    })
    .then(function(response) {
      showMellodiPlayer();
    }, function(error) {
      console.log(error);
    })
  }

  document.addEventListener('click', function(event) {
    if (event.target.matches('.track-control')) {
      if (event.target.matches('.play-pause')) {
        var selectedTrackUuid = event.target.dataset.trackUuid.toString();
        if (isSameAsNowPlayingTrack(selectedTrackUuid)) {
          mellodiPlayerAudioElement.paused ? playTrack() : pauseTrack();
        } else {
          getTrackData(selectedTrackUuid)
          .then(function(response) {
            setCurrentTrackData(response);
          }, function(error) {
            console.log(error);
          })
          .then(function(response) {
            playTrack();
          })
          .then(function(response) {
            window.clearTimeout(mellodiPlayerLogPlayTimeoutId);
            mellodiPlayerLogPlayTimeoutId = window.setTimeout(logPlay, randomTimeoutInMilliseconds(5,10), selectedTrackUuid);
          });
        }
      }

      if (event.target.matches('.previous')) {
        restartTrack();
      }
    }
  }, false)

  // Events: durationchange,

  mellodiPlayerAudioElement.addEventListener('timeupdate', function(event) {
    mellodiPlayerCurrentTimeElement.innerHTML = new Date(1000 * mellodiPlayerAudioElement.currentTime).toISOString().substr(14, 5);
    mellodiPlayerProgressBarElement.value = mellodiPlayerAudioElement.currentTime;
  });

  mellodiPlayerAudioElement.addEventListener('loadedmetadata', function(event) {
    mellodiPlayerProgressBarElement.value = mellodiPlayerAudioElement.currentTime;
    mellodiPlayerProgressBarElement.setAttribute('max', mellodiPlayerAudioElement.duration);
    mellodiPlayerCurrentTimeElement.innerHTML = new Date(1000 * mellodiPlayerAudioElement.currentTime).toISOString().substr(14, 5);
    mellodiPlayerDurationElement.innerHTML = new Date(1000 * mellodiPlayerAudioElement.duration).toISOString().substr(14, 5);
  });

  mellodiPlayerAudioElement.addEventListener('ended', function(event) {
    togglePlayPauseButtons(false);
    mellodiPlayerAudioElement.currentTime = 0;
    removeFirstUuidFromQueue();
    // play next track
  });

  mellodiPlayerAudioElement.addEventListener('pause', function(event) {
    togglePlayPauseButtons(false);
  });

  mellodiPlayerAudioElement.addEventListener('play', function(event) {
    showMellodiPlayer();
    togglePlayPauseButtons(true);
  });

  mellodiPlayerProgressBarElement.addEventListener('change', function(event) {
    mellodiPlayerAudioElement.currentTime = mellodiPlayerProgressBarElement.value;
  });

  mellodiPlayerVolumeSliderElement.addEventListener('change', function(event) {
    mellodiPlayerAudioElement.volume = event.target.value;
    if (mellodiPlayerAudioElement.volume > 0) {
      mellodiPlayerAudioElement.muted = false;
    }
  });

  mellodiPlayerVolumeMuteElement.addEventListener('click', function(event) {
    mellodiPlayerAudioElement.muted = !mellodiPlayerAudioElement.muted;
    mellodiPlayerVolumeSliderElement.value = mellodiPlayerAudioElement.muted ? 0 : mellodiPlayerAudioElement.volume;
  });

  function isSameAsNowPlayingTrack(uuid) {
    return uuid == mellodiPlayerCurrentTrackData.uuid;
  }

  function getTrackData(uuid) {
    return new Promise(function(resolve, reject) {
      var request = new XMLHttpRequest();
      request.open('GET', '/api/v1/tracks/' + uuid);

      request.onload = function() {
        if ([200,304].includes(request.status)) {
          resolve(request.response);
        } else {
          reject(Error('The track didn\'t load successfully. Error code:' + request.statusText));
        }
      };

      request.onerror = function() {
        reject(Error('There was a network error.'));
      };

      request.send();
    });
  }

  function randomTimeoutInMilliseconds(minSeconds, maxSeconds) {
    return (Math.floor(Math.random() * (maxSeconds - minSeconds + 1)) + minSeconds) * 1000
  }

  function setCurrentTrackData(data) {
    if (!data) {
      return
    };

    parsedData = JSON.parse(data);
    mellodiPlayerCurrentTrackData = parsedData;

    mellodiPlayerAudioElement.setAttribute('src', mellodiPlayerCurrentTrackData.file_url);
    mellodiPlayerAlbumCoverElement.setAttribute('src', mellodiPlayerCurrentTrackData.album.artwork_url_md);
    mellodiPlayerTrackTitleElement.innerHTML = mellodiPlayerCurrentTrackData.title;
    mellodiPlayerPlayPauseControl.dataset.trackUuid = mellodiPlayerCurrentTrackData.uuid;

    sessionStorage.setItem('nextTracksUuids', mellodiPlayerCurrentTrackData.uuid);

    resetPlayPauseButtons();
  }

  function playTrack() {
    var promise = new Promise(function(resolve, reject) {
      mellodiPlayerAudioElement.play();
    })
    .then(function(response) {
    }, function(error) {
      console.log(error);
    });
  }

  function pauseTrack() {
    mellodiPlayerAudioElement.pause();
  }

  function restartTrack() {
    mellodiPlayerAudioElement.currentTime = 0;
  }

  function logPlay(track_uuid) {
    if (!isSameAsNowPlayingTrack(track_uuid)) { return; }

    var xhr = new XMLHttpRequest();
    xhr.open('POST', '/api/v2/plays/');
    xhr.setRequestHeader('Content-Type', 'application/json');
    var params = JSON.stringify({
      play: {
        track_uuid: mellodiPlayerCurrentTrackData.uuid
      }
    })

    xhr.onload = function () {
      responseData = JSON.parse(this.response);
    };

    xhr.send(params);
  }

  function showMellodiPlayer() {
    mellodiPlayerElement.classList.add('player-open');
    document.body.classList.add('player-open');
  }

  function resetPlayPauseButtons() {
    var trackControlButtons = document.querySelectorAll('.track-control.play-pause');
    trackControlButtons.forEach(function(element) {
      element.classList.remove('playing');
    });
  }

  function togglePlayPauseButtons(isPlaying) {
    var trackControlButtons = document.querySelectorAll('.track-control.play-pause[data-track-uuid="' + mellodiPlayerCurrentTrackData.uuid + '"]');
    trackControlButtons.forEach(function(element) {
      isPlaying ? element.classList.add('playing') : element.classList.remove('playing');
    });
  };
}, false);


document.addEventListener('turbolinks:load', function() {
  const mellodiPlayerElement = document.getElementById('mellodi-player');

  checkIfPlayerIsOpen();

  function checkIfPlayerIsOpen() {
    var isPlayerOpen = mellodiPlayerElement.classList.contains('player-open');
    if (isPlayerOpen) {
      document.body.classList.add('player-open');
    }
  }
});
