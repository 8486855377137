document.addEventListener('turbolinks:load', function() {
  function toggleImageUpload() {
    $("#existing-artwork").remove();
    $("#new-artwork").show();
  }

  $(document).ready(function() {

  // Check to see if the device is a touch-screen device or not
  var supportsTouch = !!('ontouchstart' in window || navigator.msMaxTouchPoints);






  function createTag($typeahead, user_id, user_name) {
    var $user = $typeahead.parents('.nested-fields').first();
    $user.find('.user_id').val(user_id);

    var match = $user.find('.user_id').attr('id').match(/(\S+)_attributes_(\d+)_user_id/);
    var obj = $user.find('.user_id').attr('name').match(/(\S+)\[\S+\]$/)[1];

    var entity = match[1];
    var temp_id = match[2];

    var template =
    '<div class="col-xs-10">' +
      '<div class="input-wrapper">' +
        '<div class="input-group">' +
          '<div class="input">' + user_name + '</div>' +
        '</div>' +
        '<div class="input-label">Member</div>' +
      '</div>' +
    '</div>' +
    '<div class="col-xs-2">' +
      '<div class="input-wrapper">' +
        '<div class="input-group text-center">' +
          '<input id="' + entity + '_attributes_' + temp_id + '__destroy" name="' + obj + '[_destroy]" type="hidden">'+
          '<a class="icon-cross input remove_fields dynamic" href="#"></a>' +
        '</div>' +
      '</div>' +
    '</div>'

      // var templ = '<span class="new_music_page_credit_list_item">' +
      // user_name +
      // '<input id="' + entity + '_attributes_' + temp_id + '__destroy" name="' + obj + '[_destroy]" type="hidden">'+
      // '<a class="icon-cross text-danger remove_fields dynamic" href="#"></a>' +
      // '</span>';

      $user.prepend($(template));
      $typeahead.parents('.col-xs-12').first().remove();
  }

  // _.mixin({
  //   compile: function (str) {
  //     var compiled = _.template(str);
  //     compiled.render = function (context) {
  //       return this(context);
  //     };
  //     return compiled;
  //   }
  // });

  // function typeAhead() {
  //   $('.user-autocomplete').typeahead({
  //     valueKey: 'user_id',
  //       template: _.compile([
  //         '<div class="typeahead_user_photo" style="background-image: url(\'<%=user_photo_url%>\')"></div>',
  //         '<div class="typeahead_user_info_wrapper"><div class="typeaheader_user_name"><%=user_name%></div></div>'
  //       ].join('')),
  //       empty: [
  //         '<div>No Results</div>'
  //       ].join(''),
  //       limit: 5,
  //       minLength: 3,
  //       remote: {
  //           url: '/users/search?q=%QUERY',
  //           filter: function (results) {
  //               return $.map(results, function (user) {
  //                   return {
  //                     user_id: user.id,
  //                     user_name: user.name,
  //                     user_photo_url: user.image_url_xs
  //                   };
  //               });
  //           }
  //       }
  //   }).on('typeahead:selected', function(object, data) {
  //       createTag($(this), data.user_id, data.user_name);
  //   });
  // }

  // typeAhead();

  // $('#add_membership').on('cocoon:after-insert', function(e, nested_fields) {
  //   typeAhead();
  // });












  // Float labels for form inputs

  var floatLabels = function() {

    $('.input-dynamic').each(function() {
      var label = $(this).closest('.input-wrapper').find('.input-label');
      if ($(this).val()) {
        label.removeClass('empty'); // Float on page load for fields with values
      }
    })
    .on('focus', function() {

      var label = $(this).closest('.input-wrapper').find('.input-label');
      label.removeClass('empty'); // Float on field focus

    })
    .on('blur', function() {

      var label = $(this).closest('.input-wrapper').find('.input-label');
      if (!$(this).val()) {
        label.addClass('empty'); // Remove float on blur if field is still empty
      }

    });

  }

  floatLabels(); // Call on init



  var setInputCount = function() {

    var countInput = function (input) {
      var length = input.val().length || 0;
      var max = input.attr('maxlength') || null;
      if (max) {
        input.closest('.input-wrapper').find('.input-counter').text(length + '/' + max);
      } else {
        input.closest('.input-wrapper').find('.input-counter').text(length);
      }
    }

    $('.input-countable').each(function() {
      countInput($(this));
    }).on('keyup paste change', function() {
      countInput($(this));
    });

  }

  setInputCount(); // Call on init



  $('#tracks').on('cocoon:after-insert', function() {
    setInputCount();
    floatLabels();
  });






  // Textareas with the .auto-expand class automatically expand with longer text

  $('.auto-expand').each(function() {
    $(this).one('focus.textarea', function(){
      var savedValue = this.value;
      this.value = '';
      this.baseScrollHeight = this.scrollHeight;
      this.value = savedValue;
    })
    .on('input', function(){
      var minRows = this.getAttribute('data_min_rows')|0,
      rows;
      this.rows = minRows;
      rows = Math.ceil((this.scrollHeight - this.baseScrollHeight) / 22);
      this.rows = minRows + rows;
    });
  })



    //change album headers to content typed on "new" and "edit" album views
    //if #album_title is blank, Re-enter "New Music". Else, change .album_header content to #album_title content
    $('input#album_title').focusout( function() {
      var title_input = $('input#album_title').val();
      if (title_input.length < 1) {
        $('.album_form_headline').html("Untitled Album");
      } else {
        $('.album_form_headline').html(title_input);
      };
    });

    function resetAlbumPositions() {
      $('#tracks .nested-fields:visible').each(function(index, row) {
       $(row).find("input[id$='album_position']").val(index + 1);
     });
    }

    function albumCount() {
      return $('#tracks .nested-fields:visible').length;
    }

    $('#tracks').on('cocoon:after-insert', function(e, track) {
     track.find("input[id$='album_position']").val(albumCount());
   });

    $('#tracks').on('cocoon:after-remove', function(e, track) {
      resetAlbumPositions();
    });

    $(document).on('click', '.up', function() {

     var $this_row = $(this).closest('.nested-fields:visible'),
     $prev_row = $this_row.prevAll('.nested-fields:visible:first');

     if($this_row.find("input[id$='album_position']").val() > 1) {
       $this_row.insertBefore($prev_row);
       resetAlbumPositions();
     }
   });

    $(document).on('click', '.down', function() {

      var $this_row = $(this).closest('.nested-fields:visible'),
      $next_row = $this_row.nextAll('.nested-fields:visible:first');

      if($this_row.find("input[id$='album_position']").val() < albumCount()) {
        $this_row.insertAfter($next_row);
        resetAlbumPositions();
      }
    });



    $(".alert-container").fadeIn(function () {
      $(".alert-container").addClass("show");
    });
  });

  //alerts disappear automatically in 4 seconds
  window.setTimeout(function() { $(".alert-container").fadeOut(1000, function () {
    $(".alert-container").removeClass("show");
  }); }, 4000);
})
