document.addEventListener('turbolinks:load', function() {
  var checkbox = document.getElementById('sidebar-toggle');
  var sidebar = document.getElementsByTagName('sidebar')[0];
  var body = document.body;

  checkbox.addEventListener("change", function(event) {
    var checked = event.currentTarget.checked;
    var navigationMenuWrapper = event.currentTarget.parentNode;
    var hamburger = navigationMenuWrapper.getElementsByClassName('hamburger')[0];

    hamburger.classList.toggle('active', checked);
    sidebar.classList.toggle('open', checked);
    body.classList.toggle('navigation-menu-open', checked);
  })
})
