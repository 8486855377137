$(document).ready(function(){

  // When the "Read more" button for the About section is clicked, toggle both the button text and show/hiding the About paragraph
  $("#album_about_toggle").click(function() {
    $("#album_about_toggle").text(function(i, text){
      return text === "Read more" ? "Show less" : "Read more";
    })
    $(".album_about_section").toggle(); // Toggle paragraph
  });

  // When the "Display credits" button for the Credits section is clicked, toggle both the button text and show/hiding the Credits paragraph
  $("#album_credits_toggle").click(function() {
    $("#album_credits_toggle").text(function(i, text){
      return text === "Display credits" ? "Hide credits" : "Display credits";
    })
    $(".album_credits_section").toggle(); // Toggle paragraph
  });

});
